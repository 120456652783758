@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --font-base: "Poppins", sans-serif;

  --primary-color: #a0ff01;
  --secondary-color: #4731d2;
  --black-color: #030303;
  --lightGray-color: #bac1c9;
  --gray-color: #59738e;
  --brown-color: #201e1c;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
