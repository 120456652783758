.app__testimonial {
  width: 100%;
  max-width: 100vw;
}

.app__testimonial {
  position: relative;
}
.swiper {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  img {
    width: 100%;
  }
}

.test_image {
  max-width: 60%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bullets {
  width: 300px;
  margin-top: 4rem;
}

.bullet {
  width: 5rem;
  height: 4px;

  margin-left: 2rem;

  margin-top: 2rem !important;
  border-radius: 13px;
  background-color: black;
}
