.app__portfolio {
  max-width: 100vw;
}

.app_responsive_projects {
  padding-left: 5%;
  padding-right: 5%;
}
.app_responsive_design_phone {
  position: relative;

  margin-bottom: -1%;
}

.app_responsive_projects-marginAdjust {
  margin-left: -10%;
}
.app_responsive_projects-sizeAdjust {
  max-width: 80vw;
  max-height: 80vh;
}

.app_responsive_design_mac {
  position: relative;
  img {
    width: 100%;
  }
}
.about-head {
  font-size: 2.5rem;
  font-weight: 600;
  text-align: start;
  color: var(--gray-color);

  text-transform: capitalize;

  @media screen and (min-width: 2000px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 1.8rem;
  }
}

.project_image {
  position: absolute !important;

  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
}

.swiper {
  overflow: hidden;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;

  .swiper-slide {
    width: 100% !important;
  }
}

.phone_frame {
  width: 100% !important;
  z-index: 5;
}

.swiper-pagination {
  bottom: 0;
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  :hover {
    background-color: #fff;
    color: var(--gray-color);
    /*     transform: scale(1.05) rotate(-1deg);
 */
  }
}

.button-projects {
  background-color: #fff;
  border: 0 solid #e2e8f0;
  border-radius: 1.5rem;
  box-sizing: border-box;
  color: #0d172a;
  cursor: pointer;
  display: inline-block;
  margin-top: 2rem;
  font-size: 1.1rem;

  font-weight: 400;
  line-height: 1;
  padding: 1rem 1.6rem;
  text-align: center;
  text-decoration: none #0d172a solid;
  text-decoration-thickness: auto;
  transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.851);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  :hover {
    background-color: #1e293b;
    color: #fff;
  }
}
@media (max-width: 1800px) {
  .button-projects {
    font-size: 1rem;
    padding: 1rem 1rem;
  }
}

.design-text {
  font-weight: 400;
  color: var(--brown-color);
}
.business-text {
  font-weight: 400;
}

.all-projects-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  :hover {
    color: var(--gray-color);
  }
}

.active-button {
  background-color: #fff;
  color: var(--gray-color);
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}
.bg-black {
  background-color: black;
}
